import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The `}<inlineCode parentName="p">{`getAnchoredPosition`}</inlineCode>{` behavior and `}<inlineCode parentName="p">{`useAnchoredPosition`}</inlineCode>{` hook are used to calculate the position of a "floating" element that is anchored to another DOM element. This is useful for implementing overlay UI, such as dialogs, popovers, tooltips, toasts, and dropdown-style menus.`}</p>
    <p>{`At a high level, the `}<inlineCode parentName="p">{`getAnchoredPosition`}</inlineCode>{` algorithm will attempt to find the most suitable position for the floating element based on the passed-in settings, its containing element, and the size and position of the anchor element. Specifically, the calculated position should try to ensure that the floating element, when positioned at the calculated coordinates, does not overflow or underflow the container's bounding box.`}</p>
    <p>{`Settings for this behavior allow the user to customize several aspects of this calculation. See `}<strong parentName="p">{`PositionSettings`}</strong>{` below for a detailed description of these settings.`}</p>
    <h3>{`Positioning algorithm`}</h3>
    <p>{`When calculating the position of the floating element, the algorithm relies on different measurements from three separate elements:`}</p>
    <ol>
      <li parentName="ol">{`The floating element's width and height`}</li>
      <li parentName="ol">{`The anchor element's x/y position and its width and height`}</li>
      <li parentName="ol">{`The floating element's clipping container (for x/y position, width and height, and border sizes)`}</li>
    </ol>
    <p>{`The public API only asks for the first two elements; the floating element's container is discovered via DOM traversal.`}</p>
    <h4>{`Finding the floating element's clipping container`}</h4>
    <p>{`The returned anchored position calculation is relative to the floating element's closest `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/position#types_of_positioning"
      }}><em parentName="a">{`positioned`}</em></a>{` ancestor. To find this ancestor, we try to check parents of the floating element until we find one that has a position set to anything other than `}<inlineCode parentName="p">{`static`}</inlineCode>{` and use that element's bounding box as the container. If we can't find such an element, we will try to use `}<inlineCode parentName="p">{`document.body`}</inlineCode>{`.`}</p>
    <p>{`Once we have found the appropriate relative ancestor, we attempt to find the floating element's `}<em parentName="p">{`clipping container`}</em>{`. The clipping container is an element that: 1) has `}<inlineCode parentName="p">{`overflow`}</inlineCode>{` set to something other than `}<inlineCode parentName="p">{`visible`}</inlineCode>{`, and 2) is either an ancestor of the relative ancestor, or is itself the relative ancestor. Again, if we cannot locate such an element, we will use `}<inlineCode parentName="p">{`document.body`}</inlineCode>{` as the clipping container.`}</p>
    <p>{`Once we have the clipping container, its bounding box is used as the viewport for the position calculation (see the next section). If the clipping container ends up being `}<inlineCode parentName="p">{`document.body`}</inlineCode>{`, we take one additional step, allowing the clipping rectangle to be at least as tall as the window. This is done because the `}<inlineCode parentName="p">{`body`}</inlineCode>{` element doesn't take up the full window size by default, but we still want to allow the entire space to be used as the viewport for the position calculation. It may be a good idea to ensure that this clipping container element `}<em parentName="p">{`also`}</em>{` contains the anchor element and is scrollable. This will ensure that if scrolled, the anchor and floating element will move together.`}</p>
    <h4>{`Positioning and overflow`}</h4>
    <p>{`With the positions and sizes of the above DOM elements, the algorithm calculates the (x, y) coordinate for the floating element. Then, it checks to see if, based on the floating element's size, if it would overflow the bounds of the container. If it would, it does one of two things:`}</p>
    <p>{`A) If the overflow happens in the same direction as the anchor side (e.g. side is `}<inlineCode parentName="p">{`'outside-bottom'`}</inlineCode>{` and the overflowing portion of the floating element is the bottom), try to find a different side, recalculate the position, and check for overflow again. If we check all four sides and don't find one that fits, revert to the bottom side, in hopes that a scrollbar might appear.
B) Otherwise, adjust the alignment offset so that the floating element can stay inside the container's bounds.`}</p>
    <p>{`For a more in-depth explanation of the positioning settings, see `}<inlineCode parentName="p">{`PositionSettings`}</inlineCode>{` below.`}</p>
    <h3>{`Demo`}</h3>
    <p>{`Deploy Storybook to see a live demo of `}<inlineCode parentName="p">{`anchoredPosition`}</inlineCode>{`.`}</p>
    <h3>{`Usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const settings = {
  side: 'outside-right',
  align: 'center',
  alignmentOffset: 10,
  anchorOffset: -10,
} as Partial<PositionSettings>
const float = document.getElementById('floatingElement')
const anchor = document.getElementById('anchorElement')
const {top, left} = getAnchoredPosition(float, anchor, settings)
float.style.top = \`\${top}px\`
float.style.left = \`\${left}px\`
`}</code></pre>
    <h3>{`API`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`getAnchoredPosition`}</inlineCode>{` function takes the following arguments.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`floatingElement`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is an Element that is currently rendered on the page. `}<inlineCode parentName="td">{`getAnchoredPosition`}</inlineCode>{` needs to be able to measure this element's `}<inlineCode parentName="td">{`width`}</inlineCode>{` and `}<inlineCode parentName="td">{`height`}</inlineCode>{`.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`anchorElement`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`Element`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This is an Element that the floating element will be "anchored" to. In other words, the calculated position of the floating element will be based on this element's position and size.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`settings`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`PositionSettings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`{}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Settings to customize the positioning algorithm. See below for a description of each setting.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`PositionSettings interface`}</h4>
    <p><inlineCode parentName="p">{`PositionSettings`}</inlineCode>{` is an object with the following interface`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`side`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`AnchorSide`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`"outside-bottom"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Sets the side of the anchor element that the floating element should be pinned to. This side is given by a string starting with either `}<inlineCode parentName="td">{`inside`}</inlineCode>{` or `}<inlineCode parentName="td">{`outside`}</inlineCode>{`, followed by a hyphen, followed by either `}<inlineCode parentName="td">{`top`}</inlineCode>{`, `}<inlineCode parentName="td">{`right`}</inlineCode>{`, `}<inlineCode parentName="td">{`bottom`}</inlineCode>{`, or `}<inlineCode parentName="td">{`left`}</inlineCode>{`. Additionally, `}<inlineCode parentName="td">{`"inside-center"`}</inlineCode>{` is an allowed value.`}<br /><br />{`The first part of this string, `}<inlineCode parentName="td">{`inside`}</inlineCode>{` or `}<inlineCode parentName="td">{`outside`}</inlineCode>{`, determines whether the floating element should be attempted to be placed "inside" the anchor element or "outside" of it. Using `}<inlineCode parentName="td">{`inside`}</inlineCode>{` is useful for making it appear that the anchor `}<em parentName="td">{`contains`}</em>{` the floating element, and it can be used for implementing a dialog that is centered on the screen. The `}<inlineCode parentName="td">{`outside`}</inlineCode>{` value is more common and can be used for tooltips, popovers, menus, etc.`}<br /><br />{`The second part of this string determines the `}<em parentName="td">{`edge`}</em>{` on the anchor element that the floating element will be anchored to. If side is `}<inlineCode parentName="td">{`"inside-center"`}</inlineCode>{`, then the floating element will be centered in the X-direction (while `}<inlineCode parentName="td">{`align`}</inlineCode>{` is used to position it in the Y-direction).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`align`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`AnchorAlignment`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`"start"`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Determines how the floating element should align with the anchor element. If set to `}<inlineCode parentName="td">{`"start"`}</inlineCode>{`, the floating element's first edge (top or left) will align with the anchor element's first edge. If set to `}<inlineCode parentName="td">{`"center"`}</inlineCode>{`, the floating element will be centered along the axis of the anchor edge. If set to `}<inlineCode parentName="td">{`"end"`}</inlineCode>{`, the floating element's last edge will align with the anchor element's last edge.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`anchorOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`4`}</inlineCode>{`*`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The number of pixels between the anchor edge and the floating element. Positive values move the floating element farther from the anchor element (for outside positioning) or further inside the anchor element (for inside positioning). Negative values have the opposite effect.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`alignmentOffset`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`number`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`4`}</inlineCode>{`*`}{`*`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`An additional offset, in pixels, to move the floating element from the aligning edge. Positive values move the floating element in the direction of center-alignment. Negative values move the floating element away from center-alignment. When align is `}<inlineCode parentName="td">{`"center"`}</inlineCode>{`, positive offsets move the floating element right (top or bottom anchor side) or down (left or right anchor side).`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`allowOutOfBounds`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`false`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If false, when the above settings result in rendering the floating element wholly or partially off-screen, attempt to adjust the settings to prevent this. Only applies to `}<inlineCode parentName="td">{`outside`}</inlineCode>{` positioning.`}<br /><br />{`First, attempt to flip to the opposite edge of the anchor if the floating element is getting clipped in that direction. If flipping results in a similar clipping, try moving to the adjacent sides.`}<br /><br />{`Once we find a side that does not clip the overlay in its own dimension, check the rest of the sides to see if we need to adjust the alignment offset to fit in other dimensions.`}<br /><br />{`If we try all four sides and get clipped each time, settle for overflowing and use the `}<inlineCode parentName="td">{`bottom`}</inlineCode>{` side, since the ability to scroll is most likely in this direction.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`*`}{` If `}<inlineCode parentName="p">{`side`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`"inside-center"`}</inlineCode>{`, this defaults to `}<inlineCode parentName="p">{`0`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`4`}</inlineCode>{`.`}</p>
    <p>{`*`}{`*`}{` If using outside positioning, or if `}<inlineCode parentName="p">{`align`}</inlineCode>{` is set to `}<inlineCode parentName="p">{`"center"`}</inlineCode>{`, this defaults to `}<inlineCode parentName="p">{`0`}</inlineCode>{` instead of `}<inlineCode parentName="p">{`4`}</inlineCode>{`.`}</p>
    <h4>{`AnchorSide`}</h4>
    <p><inlineCode parentName="p">{`AnchorSide`}</inlineCode>{` can be any of the following strings:`}</p>
    <p><inlineCode parentName="p">{`'inside-top'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'inside-bottom'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'inside-left'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'inside-right'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'inside-center'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'outside-top'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'outside-bottom'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'outside-left'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'outside-right'`}</inlineCode></p>
    <h4>{`AnchorAlignment`}</h4>
    <p><inlineCode parentName="p">{`AnchorAlignment`}</inlineCode>{` can be any of the following strings:`}</p>
    <p><inlineCode parentName="p">{`'start'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'center'`}</inlineCode>{`, `}<inlineCode parentName="p">{`'end'`}</inlineCode></p>
    <h3>{`Best practices`}</h3>
    <p>{`As discussed above, the positioning algorithm needs to first measure the size of three different elements. Therefore, all three of these elements (anchor element, floating element, and the floating element's closest positioned container) must be rendered at the time `}<inlineCode parentName="p">{`getAnchoredPosition`}</inlineCode>{` is called. To avoid a frame where the floating element is rendered at the `}<inlineCode parentName="p">{`(0, 0)`}</inlineCode>{` position, give it a style of `}<inlineCode parentName="p">{`visibility: hidden`}</inlineCode>{` until its position is returned at set. This allows the element to be measured without showing up on the page.`}</p>
    <h3>{`A note on performance`}</h3>
    <p>{`Every time `}<inlineCode parentName="p">{`getAnchoredPosition`}</inlineCode>{` is called, it causes a `}<a parentName="p" {...{
        "href": "https://developers.google.com/speed/docs/insights/browser-reflow"
      }}>{`reflow`}</a>{` because it needs to query the rendering engine for the positions of 3 elements: the anchor element, the floating element, and the closest ancestor of the floating element that is `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/CSS/position#types_of_positioning"
      }}><em parentName="a">{`positioned`}</em></a>{`. Therefore, this function should not be called until it is needed (e.g. an overlay-style menu is invoked and displayed).`}</p>
    <h2>{`useAnchoredPosition hook (private to `}<inlineCode parentName="h2">{`@primer/react`}</inlineCode>{`)`}</h2>
    <p>{`The `}<inlineCode parentName="p">{`useAnchoredPosition`}</inlineCode>{` hook is used to provide anchored positioning data for React components. The hook returns refs that must be added to the anchor and floating elements, and a `}<inlineCode parentName="p">{`position`}</inlineCode>{` object containing `}<inlineCode parentName="p">{`top`}</inlineCode>{` and `}<inlineCode parentName="p">{`left`}</inlineCode>{`. This position is tracked as state, so the component will re-render whenever it changes. It is the responsibility of the consumer to apply the top and left styles to the floating element in question.`}</p>
    <h3>{`Using your own refs`}</h3>
    <p>{`The `}<inlineCode parentName="p">{`useAnchoredPosition`}</inlineCode>{` hook will return two refs for the anchor element and the floating element, which must be added to their respective JSX. If you would like to use your own refs, you can pass them into the hook as part of the settings object (see the interface below).`}</p>
    <h3>{`Recalculating position`}</h3>
    <p>{`Like other hooks such as `}<inlineCode parentName="p">{`useCallback`}</inlineCode>{` and `}<inlineCode parentName="p">{`useEffect`}</inlineCode>{`, this hook takes a dependencies array. If defined, the position will only be recalculated when one of the dependencies in this array changes. Otherwise, the position will be calculated when the component is first mounted, but never again.`}</p>
    <h3>{`useAnchoredPosition usage`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`export const AnchoredPositionExample = () => {
  const {floatingElementRef, anchorElementRef, position} = useAnchoredPosition({side: 'outside-bottom', align: 'center'})
  return (
    <div>
      <Box
        position="absolute"
        top={position?.top ?? 0}
        left={position?.left ?? 0}
        width={150}
        height={150}
        ref={floatingElementRef as React.RefObject<HTMLDivElement>}
      >
        Floating element
      </Box>
      <Box borderWidth='1px' borderStyle='solid' borderColor='border.default' borderRadius={2} width={400} height={75} ref={anchorElementRef as React.RefObject<HTMLDivElement>}>
        Anchor Element
      </Box>
    </div>
  )
}
`}</code></pre>
    <h3>{`useAnchoredPosition`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`settings`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`AnchoredPositionHookSettings`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Optional settings to control how the anchored position is calculated. See below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`dependencies`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.DependencyList`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`undefined`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`Dependencies to determine when to re-calculate the position. If undefined or `}<inlineCode parentName="td">{`[]`}</inlineCode>{`, only calculate the position once.`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Return value`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`floatingElementRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<Element>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This ref must be added to the floating element JSX`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`anchorElementRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<Element>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`This ref must be added to the anchor element JSX`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`position`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`{top: number, left: number}`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`The calculated position`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`AnchoredPositionHookSettings interface`}</h3>
    <p><inlineCode parentName="p">{`AnchoredPositionHookSettings`}</inlineCode>{` is an object with an interface that extends `}<inlineCode parentName="p">{`PositionSettings`}</inlineCode>{` (see above). Additionally, it adds the following properties:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Name`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`floatingElementRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If provided, this will be the ref used to access the element that will be used for the floating element. Its size measurements are needed by the underlying `}<inlineCode parentName="td">{`useAnchoredPosition`}</inlineCode>{` behavior. Otherwise, this hook will create the ref for you and return it. In both cases, the ref must be provided to the floating element's JSX.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`anchorElementRef`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><inlineCode parentName="td">{`React.RefObject<HTMLElement>`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "center"
          }}><inlineCode parentName="td">{`undefined`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`If provided, this will be the ref used to access the element that will be used for the anchor element. Its position and size measurements are needed by the underlying `}<inlineCode parentName="td">{`useAnchoredPosition`}</inlineCode>{` behavior. Otherwise, this hook will create the ref for you and return it. In both cases, the ref must be provided to the anchor element's JSX.`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      